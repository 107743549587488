@import url(/src/assets/css/element-variables.scss);

.header[data-v-5798ccd0] {
  border-bottom: 3px solid rgb(31 163 162);
  padding-top: 25px;
  padding-bottom: 10px;
}
@media (min-width: 1200px) {
.container[data-v-5798ccd0],
  .container-sm[data-v-5798ccd0],
  .container-md[data-v-5798ccd0],
  .container-lg[data-v-5798ccd0],
  .container-xl[data-v-5798ccd0] {
    max-width: 1170px;
}
}

/* ===================
nav menu css
====================== */
.navbar[data-v-5798ccd0] {
  background: #fff;
  padding-top: 0px;
  padding-bottom: 0px;
  z-index: 999;
}
.nav-item[data-v-5798ccd0] {
  margin: 0 46px 0 0;
}
.header-user[data-v-5798ccd0] {
  margin-right: 15px;
  font-size: 16px;
}
.header-user .icon[data-v-5798ccd0] {
  margin-right: 10px;
}
@keyframes slide-down-5798ccd0 {
0% {
    transform: translateY(-100%);
}
100% {
    transform: translateY(0);
}
}
.navbar.fixed-top .nav-item .nav-link[data-v-5798ccd0]::before {
  color: #00235A;
}
button.navbar-toggler[data-v-5798ccd0] {
  font-size: 3.5rem;
  padding: 0;
  margin: 0;
}
button.navbar-toggler[data-v-5798ccd0]:focus {
  outline: none;
  box-shadow: none;
}
.navbar-toggler-icon[data-v-5798ccd0] {
  display: inline-block;
  width: inherit;
  height: inherit;
}
.navbar-brand img[data-v-5798ccd0] {
}
.nav-category button[data-v-5798ccd0] {
  background: transparent;
  border: none;
  font-weight: 500;
  color: #202029;
  padding: 10px 0;
  transition: all .5s ease-in-out;
}
.nav-category button[data-v-5798ccd0]:hover {
  color: #00AF92;
}
.nav-category button i[data-v-5798ccd0] {
  color: #00AF92;
  margin-right: 5px;
}
.nav-category .dropdown-menu .dropdown-item[data-v-5798ccd0] {
  font-weight: 500;
  color: #202029;
  transition: all .5s ease-in-out;
}
.nav-category .dropdown-menu .dropdown-item[data-v-5798ccd0]:hover {
  background: transparent;
  color: #00AF92;
  padding-left: 25px;
}


/* dropdown toggler */
.dropdown-toggle[data-v-5798ccd0]::after {
  display: inline-block;
  margin-left: 5px;
  vertical-align: middle;
  font-family: 'Font Awesome 5 Pro';
  content: "\f107";
  font-weight: 500;
  border: none;
  font-size: 16px;
}

/* mobile menu */
.mobile-menu-right[data-v-5798ccd0] {
  display: flex;
  align-items: center;
}
@media all and (min-width: 992px) {
.navbar .nav-item .nav-link[data-v-5798ccd0] {
    padding: 25px 0 25px 0;
    font-size: 16px;
    font-weight: 500;
    color: #202029;
    text-transform: capitalize;
}
.navbar .nav-item:last-child .nav-link[data-v-5798ccd0] {
    margin-right: 0;
}
.navbar .nav-item .dropdown-menu li[data-v-5798ccd0] {
    border-bottom: 1px solid #eee;
}
.navbar .nav-item .dropdown-menu li[data-v-5798ccd0]:last-child {
    margin-bottom: 0;
    border-bottom: none;
}
.navbar .nav-item .dropdown-menu .dropdown-item[data-v-5798ccd0] {
    font-size: 15px;
    font-weight: 500;
    padding: 8px 15px;
    color: #202029;
    position: relative;
    text-transform: capitalize;
    transition: all .5s ease-in-out;
}
.navbar .nav-item .dropdown-menu .dropdown-item[data-v-5798ccd0]:hover {
    background: transparent;
    color: #00AF92;
    padding-left: 25px;
}
.navbar .nav-item .nav-link[data-v-5798ccd0] {
    position: relative;
}
.navbar .nav-item .nav-link.active[data-v-5798ccd0],
  .navbar .nav-item:hover .nav-link[data-v-5798ccd0] {
    color: #00AF92;
}
.navbar .nav-item:hover .dropdown-menu[data-v-5798ccd0] {
    transition: .3s;
    opacity: 1;
    visibility: visible;
    top: 100%;
    transform: rotateX(0deg);
}
.navbar .dropdown-menu-end[data-v-5798ccd0] {
    right: 0;
    left: auto;
}
.navbar .dropdown-menu.fade-down[data-v-5798ccd0] {
    top: 80%;
    transform: rotateX(-75deg);
    transform-origin: 0% 0%;
}
.navbar .dropdown-menu.fade-up[data-v-5798ccd0] {
    top: 140%;
}
.header-nav-right[data-v-5798ccd0] {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-left: 30px;
}
.header-nav-search a[data-v-5798ccd0] {
    color: #202029;
    font-size: 18px;
    padding: 5px 10px;
}
.header-nav-search a[data-v-5798ccd0]:hover {
    color: #00AF92;
}
.header-cart a[data-v-5798ccd0] {
    font-size: 18px;
    position: relative;
}
.header-cart a[data-v-5798ccd0]:hover {
    color: #00AF92;
}
.header-cart a span[data-v-5798ccd0] {
    background: #00AF92;
    color: #fff;
    font-size: 12px;
    font-weight: 500;
    position: absolute;
    width: 18px;
    height: 18px;
    line-height: 18px;
    border-radius: 50px;
    text-align: center;
    top: -5px;
    right: -10px;
}
.header-btn[data-v-5798ccd0] {
    background: #00AF92;
    color: #fff;
    padding: 8px 18px;
    border-radius: 5px;
    font-weight: 500;
    transition: all .5s ease-in-out;
}
.header-btn[data-v-5798ccd0]:hover {
    background: #202029;
    color: #fff;
}
#main_nav[data-v-5798ccd0] {
    justify-content: flex-end;
}
}
@media all and (max-width: 1199px) {
.navbar .header-btn[data-v-5798ccd0] {
    display: none;
}
.navbar .header-btn[data-v-5798ccd0] {
    display: none;
}
.nav-category[data-v-5798ccd0] {
    display: none;
}
}
@media all and (max-width: 991px) {
.navbar[data-v-5798ccd0] {
    top: 0;
    right: 0;
    left: 0;
    position: fixed;
}
.navbar-brand[data-v-5798ccd0] {
    padding-left: 10px;
}
.navbar-brand img[data-v-5798ccd0] {
    width: 190px;
}
.navbar-collapse[data-v-5798ccd0] {
    max-height: 290px;
    overflow: hidden;
    overflow-y: auto;
    padding: 0 20px;
    background-color: #fff;
}
.dropdown-toggle[data-v-5798ccd0]::after {
    float: right;
}
.navbar .nav-item .nav-link[data-v-5798ccd0] {
    color: #202029;
    font-weight: 700;
    transition: all .5s ease-in-out;
}
.navbar .nav-item .nav-link[data-v-5798ccd0]:hover {
    color: #00AF92 !important;
}
.navbar-toggler-icon[data-v-5798ccd0] {
    font-size: 32px;
    color: #202029;
    font-weight: 500;
    padding-right: 10px;
    margin-bottom: 10px;
}
.navbar .dropdown-menu[data-v-5798ccd0] {
    border-radius: 0px;
}
.navbar.fixed-top .navbar-toggler-icon[data-v-5798ccd0] {
    color: #202029;
}
.header-nav-right[data-v-5798ccd0] {
    display: none;
}
}
.start-vm[data-v-5798ccd0] {
  display: flex;
  margin-right: 46px;
  color: #2b85fb;
}
.start-vm img[data-v-5798ccd0] {
  border: 2px solid #2b85fb;
  border-radius: 100%;
  padding: 7px;
  margin-right: 15px;
}
.start-vm .text[data-v-5798ccd0] {
  display: grid;
  text-align: center;
}
.copyright[data-v-5798ccd0] {
  padding: 20px 0;
  border-top: 1px solid #dee0e4;
}
.copyright .copyright-text[data-v-5798ccd0] {
  color: #00184f;
  margin-bottom: 0px;
  font-weight: 500;
  font-size: 17px;
}
.copyright .copyright-text a[data-v-5798ccd0] {
  color: #00AF92;
}
.dropdown:hover > .dropdown-menu[data-v-5798ccd0] {
  display: block;
}
.dropdown > .dropdown-toggle[data-v-5798ccd0]:active {
  pointer-events: none;
}
.dropdown-item[data-v-5798ccd0] {
  font-size: 14px;
}







/*# sourceMappingURL=chunk-090aef0f.e23826da.css.map*/